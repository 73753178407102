export const dataReducer = (state, action) => {
  switch (action.type) {
    case 'SET_QUESTIONS':
      return { ...state, questions: action.questions }
    case 'SET_VEHICLE':
      return { ...state, vehicle: action.vehicle }
    case 'SET_VALIDATION_ERROR':
      return { ...state, validation: action.validation }
    case 'SET_FETCH_ERROR':
      return { ...state, error: action.error }
    default:
      return state
  }
}
