import React, { createContext, useReducer, useEffect } from 'react'
import { node } from 'prop-types'

import { formReducer } from '../../../reducer/form-reducer'

const FormContext = createContext()
const FormProvider = ({ children }) => {
  const localState = process.browser
    ? JSON.parse(sessionStorage.getItem('checkoutFormData'))
    : undefined

  const initialState = {
    formData: {},
    accountDetails: {},
    financeDetails: {},
    result: {},
    section: 0,
    subSection: 0
  }
  const [form, dispatch] = useReducer(formReducer, localState || initialState)

  useEffect(() => {
    sessionStorage.setItem('checkoutFormData', JSON.stringify(form))
  }, [form])

  return (
    <FormContext.Provider value={{ form, dispatch }}>
      {children}
    </FormContext.Provider>
  )
}

FormProvider.propTypes = {
  children: node.isRequired
}

export { FormContext, FormProvider }
