export const formReducer = (state, action) => {
  switch (action.type) {
    // data that's for citygate / autoworks only
    case 'SET_FORM':
      return {
        ...state,
        formData: { ...state.formData, [action.key]: action.value }
      }

    // data for eVA
    case 'SET_INPUT':
      return {
        ...state,
        inputData: { ...state.inputData, [action.key]: action.value }
      }

    case 'LOAD_DATA': {
      const { inputData, section, subSection, result, ...formData } =
        action.data
      return {
        ...state,
        inputData,
        formData,
        section,
        subSection,
        result
      }
    }
    case 'SET_DEFAULTS':
      return {
        ...state,
        inputData: { ...state.inputData, ...action.defaults }
      }
    case 'RESET_FORM':
      return {
        ...state,
        section: 0,
        subSection: 0,
        formData: {},
        inputData: {},
        result: {}
      }
    case 'SET_RESULT':
      return {
        ...state,
        result: { ...state.result, ...action.result }
      }
    case 'SET_SECTION': {
      return { ...state, section: action.section }
    }
    case 'SET_SUBSECTION': {
      return { ...state, subSection: action.subSection }
    }
    default:
      return state
  }
}
