import { useCallback, useState, useEffect } from 'react'

function useBroadcastState(event, initialState) {
  // Get the initial value from local storage. Remove the initializer if you
  // don't intend to store the value _anyway_. For our settings, it makes
  // perfect sense, as they're not sensitive.
  const [value, setValue] = useState(() =>
    typeof localStorage !== 'undefined' && localStorage.getItem(event)
      ? JSON.parse(localStorage.getItem(event))
      : initialState
  )

  useEffect(() => {
    const onStorage = (ev) => {
      if (ev.key !== event) {
        return
      }

      setValue(JSON.parse(ev.newValue))
    }

    // This will trigger on storage events. That usually means that it will
    // trigger in all browsing contexts that are currently blurred (so not the
    // current, active/focussed one). This doesn't hold for older browsers. IE10
    // for example will send the event to the calling tab as well. That's okay,
    // just expect a double render.
    window.addEventListener('storage', onStorage)

    return () => {
      window.removeEventListener('storage', onStorage)
    }
  }, [event])

  const setAndBroadcastValue = useCallback(
    (nextValue) => {
      // Broadcast the value
      localStorage.setItem(event, JSON.stringify(nextValue))

      // ...but also update it locally
      setValue(nextValue)
    },
    [event]
  )

  return [value, setAndBroadcastValue]
}

export default useBroadcastState
