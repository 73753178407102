export const defaultForm = () => ({
  firstName: '',
  lastName: '',
  emailAddress: '',
  password: '',
  emailNewsletter: false,
  tcs: false
})

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.user, loginForms: defaultForm() }
    case 'LOGOUT':
      return { ...state, user: null, loginForms: defaultForm() }
    case 'SET_FORM':
      return {
        ...state,
        loginForms: { ...state.loginForms, [action.key]: action.value }
      }
    case 'RESET_FORM':
      return {
        ...state,
        loginForms: action.form || defaultForm()
      }
    default:
      return state
  }
}
