import email from 'email-validator'
import { getHttpClient } from './graphql-client'
import handleError, { createValidationError } from './request-error-handler'

const client = getHttpClient()

const userFields = `
  _id
  firstName
  lastName
  emailAddress
  favourites
  permissions {
    marketing {
      email
    }
  }
`

const loginMutation = `mutation ($emailAddress: String, $password: String) {
  login(emailAddress: $emailAddress, password: $password) {
    ${userFields}
  }
}`

const loginSendLinkMutation = `mutation ($emailAddress: String) {
  loginSendLink(emailAddress: $emailAddress) {
    success
  }
}`

const loginLinkMutation = `mutation ($userId: String, $auth: String) {
  loginLink(userId: $userId, auth: $auth) {
    _id
    firstName
    lastName
    emailAddress
    favourites
    passwordSet
    permissions {
      marketing {
        email
      }
    }
  }
}`

const logoutMutation = `mutation {
  logout {
    loggedOut
  }
}`

const userQuery = `{
  user {
    ${userFields}
  }
}`

const registerMutation = `mutation ($request: PersonInput) {
  register(registerRequest: $request) {
    success
  }
}`

const profileMutation = `mutation ($request: PersonProfileInput) {
  updateProfile(profileRequest: $request) {
    ${userFields}
  }
}`

const forgotPasswordMutation = `mutation ($emailAddress: String) {
  forgotPasswordRequest(
    emailAddress: $emailAddress
  ) {
    sent
  }
}`

const resetPasswordMutation = `mutation ($emailAddress: String, $password: String, $token: String, $expiry: String) {
  forgotPassword(
    password: $password
    emailAddress: $emailAddress
    token: $token
    expiry: $expiry
) {
    success
    error
  }
}`

const setPasswordMutation = `mutation ($password: String!) {
  setPassword(password: $password) {
    success
  }
}`

export const login = async (emailAddress, password) => {
  if (!email.validate(emailAddress)) {
    throw new Error('Please enter a valid email address')
  }

  try {
    const { login } = await client.request(loginMutation, {
      emailAddress,
      password
    })

    return login
  } catch (e) {
    if (!e.response || e.response.errors) {
      throw e
    }
    throw new Error(e.response.errors[0].message)
  }
}

export const loginSendLink = async (emailAddress) => {
  if (!email.validate(emailAddress)) {
    throw new Error('Please enter a valid email address')
  }

  try {
    const { loginSendLink } = await client.request(loginSendLinkMutation, {
      emailAddress
    })

    return loginSendLink
  } catch (e) {
    if (!e.response || e.response.errors) {
      throw e
    }
    throw new Error(e.response.errors[0].message)
  }
}

export const loginLink = async (userId, auth) => {
  try {
    const { loginLink } = await client.request(loginLinkMutation, {
      userId,
      auth
    })
    return loginLink
  } catch (e) {
    if (!e.response || e.response.errors) {
      throw e
    }
    throw new Error(e.response.errors[0].message)
  }
}

export const logout = async () => {
  try {
    const { logout } = await client.request(logoutMutation)
    return logout
  } catch (e) {
    if (!e.response || e.response.errors) {
      throw e
    }

    throw new Error(e.response.errors[0].message)
  }
}

export const getUser = async () => {
  try {
    const { user } = await client.request(userQuery)

    return user
  } catch (e) {
    if (!e.response || e.response.errors) {
      throw e
    }

    throw new Error(e.response.errors[0].message)
  }
}

export const forgotPassword = async (emailAddress) => {
  if (!email.validate(emailAddress)) {
    throw new Error('Invalid Email')
  }

  try {
    const res = await client.request(forgotPasswordMutation, {
      emailAddress
    })

    return res
  } catch (e) {
    if (!e.response || e.response.errors) {
      throw e
    }

    throw new Error(e.response.errors[0].message)
  }
}

export const resetPassword = async (password, query) => {
  try {
    const res = await client.request(resetPasswordMutation, {
      password,
      ...query
    })

    return res
  } catch (e) {
    if (!e.response || !e.response.errors) {
      throw e
    }

    const error = e.response.errors[0]
    if (error.state) {
      throw createValidationError(error.state)
    }
    throw new Error(error.message)
  }
}

export const setPassword = async (password) => {
  try {
    const { setPassword } = await client.request(setPasswordMutation, {
      password
    })

    return setPassword.success
  } catch (e) {
    handleError(e)
  }
}

export const register = async (formData, favourites = []) => {
  if (!email.validate(formData.emailAddress)) {
    throw createValidationError({
      emailAddress: 'Please enter a valid email address'
    })
  }

  if (!formData.tcs) {
    throw createValidationError({
      tcs: 'Please accept the terms and conditions'
    })
  }

  formData.permissions = {
    marketing: { email: formData.emailNewsletter }
  }

  delete formData.emailNewsletter
  delete formData.tcs

  formData.favourites = favourites

  try {
    const { register } = await client.request(registerMutation, {
      request: formData
    })

    return register
  } catch (e) {
    handleError(e)
  }
}

export const updateProfile = async (formData) => {
  if (!email.validate(formData.emailAddress)) {
    throw createValidationError({
      emailAddress: 'Please enter a valid email address'
    })
  }

  formData.permissions = {
    marketing: { email: formData.emailNewsletter }
  }

  delete formData.emailNewsletter
  delete formData.tcs

  try {
    const { updateProfile } = await client.request(profileMutation, {
      request: formData
    })

    return updateProfile
  } catch (e) {
    handleError(e)
  }
}
