export const createValidationError = (errors) => {
  const error = new Error('Validation Error')

  error.validation = true
  error.errors = errors

  return error
}

export default function handleError(e) {
  if (!e.response || !e.response.errors) {
    throw e
  }

  const error = e.response.errors[0]
  if (error.state) {
    throw createValidationError(error.state)
  }
  throw new Error(error.message)
}
