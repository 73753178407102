import set from 'lodash.set'
import cloneDeep from 'lodash.clonedeep'

export const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ACCOUNT_DETAILS':
      return {
        ...state,
        accountDetails: { ...state.accountDetails, ...action.details }
      }
    case 'SET_FINANCE_DETAILS': {
      const newState = cloneDeep(state.financeDetails)
      Object.entries(action.details).forEach(([key, value]) => {
        set(newState, key, value)
      })
      return {
        ...state,
        financeDetails: newState
      }
    }
    case 'LOAD_FINANCE_DETAILS':
      return {
        ...state,
        financeDetails: action.details
      }
    case 'RESET_FINANCE_DETAILS':
      return {
        ...state,
        financeDetails: {
          mainApplicant: {}
        }
      }
    default:
      return state
  }
}
