export const dataReducer = (state, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      return { ...state, error: action.error, validation: action.validation }

    case 'SET_CHECKOUT_VEHICLE':
      return {
        ...state,
        checkout: { ...state.checkout, vehicle: action.vehicle }
      }

    case 'SET_CHECKOUT_DEAL':
      return {
        ...state,
        checkout: { ...state.checkout, deal: action.deal }
      }

    case 'SET_CHECKOUT_RESERVATION':
      return {
        ...state,
        checkout: { ...state.checkout, reservation: action.reservation }
      }

    case 'SET_CHECKOUT_NOT_VALID':
      return {
        ...state,
        checkout: { ...state.checkout, notValid: action.notValid }
      }

    case 'SET_CHECKOUT_ERRORS':
      return {
        ...state,
        checkout: { ...state.checkout, errors: action.errors }
      }
    default:
      return state
  }
}
