import React from 'react'
import { AuthProvider } from './AuthContext'
import { DataProvider } from './DataContext'
import { FormProvider } from './FormContext'
import { ValuationDataProvider } from 'CGV/component/Context/DataContext'
import { ValuationFormProvider } from 'CGV/component/Context/FormContext'

const composeProviders =
  (...Providers) =>
  (Child) =>
  (props) =>
    Providers.reduce((acc, Provider) => {
      return (
        <Provider {...props} key={Provider.name}>
          {acc}
        </Provider>
      )
    }, <Child {...props} />)

const Container = ({ children }) => children

const ContextProvider = composeProviders(
  AuthProvider,
  DataProvider,
  FormProvider,
  ValuationDataProvider,
  ValuationFormProvider
)(Container)

export default ContextProvider
