import React, { createContext, useReducer, useEffect } from 'react'
import { node } from 'prop-types'

import { formReducer } from '../../../reducer/form-reducer'

const ValuationFormContext = createContext()

const ValuationFormProvider = ({ children }) => {
  const localState = process.browser
    ? JSON.parse(sessionStorage.getItem('valuationFormData'))
    : undefined

  const initialState = {
    formData: {},
    inputData: {},
    result: {},
    section: 0,
    subSection: 0
  }
  const [form, dispatch] = useReducer(formReducer, localState || initialState)

  useEffect(() => {
    sessionStorage.setItem('formData', JSON.stringify(form))
  }, [form])

  return (
    <ValuationFormContext.Provider value={{ form, dispatch }}>
      {children}
    </ValuationFormContext.Provider>
  )
}

ValuationFormProvider.propTypes = {
  children: node.isRequired
}

export { ValuationFormContext, ValuationFormProvider }
