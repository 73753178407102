import React from 'react'

import {
  CardElement as BaseCardElement,
  CardNumberElement as BaseCardNumberElement,
  CardExpiryElement as BaseCardExpiryElement,
  CardCvcElement as BaseCardCvcElement
} from '@stripe/react-stripe-js'

import styles from './StripeElements.module.scss'

const baseStyles = {
  fontFamily:
    'FFMark, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  fontSize: '16px',
  backgroundColor: '#fff',
  color: 'inherit',
  lineHeight: '24px'
}

const classes = {
  base: styles.StripeElementsBase,
  complete: styles.complete,
  empty: styles.empty,
  focus: styles.focus,
  invalid: styles.invalid
}

export const fonts = [
  {
    family: 'FFMark',
    src: 'url(/font/5423483/90214997-3760-4b80-a23b-fec02ab55fac.woff)',
    weight: '400'
  }
]

export const CardElement = (options, ...other) => (
  <BaseCardElement
    {...other}
    options={{ classes, ...options, style: { base: baseStyles } }}
  />
)

export const CardNumberElement = (options, ...other) => (
  <BaseCardNumberElement
    {...other}
    options={{ classes, ...options, style: { base: baseStyles } }}
  />
)

export const CardExpiryElement = (options, ...other) => (
  <BaseCardExpiryElement
    {...other}
    options={{ classes, ...options, style: { base: baseStyles } }}
  />
)

export const CardCvcElement = (options, ...other) => (
  <BaseCardCvcElement
    {...other}
    options={{ classes, ...options, style: { base: baseStyles } }}
  />
)
