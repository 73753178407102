import React, { useEffect, useState } from 'react'
import { func, object } from 'prop-types'
import Head from 'next/head'
import Router from 'next/router'

import '../CitygateDesignSystem/src/asset/scss/base.scss'
import ContextProvider from 'src/component/Context'

import * as Sentry from '@sentry/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { fonts } from '@/component/Primitive/StripeElements'

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY)

Sentry.init({
  dsn: 'https://d99a45ae14434ae59a49a59bc0b571c8@o36550.ingest.sentry.io/5305680'
})

const MyApp = ({ Component, pageProps }) => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const handleRouteChange = (url) => {
      Sentry.configureScope((scope) => {
        scope.setTag('route', url)
      })

      setLoading(true)
    }

    const handleRouteComplete = () => {
      setLoading(false)
    }

    Router.events.on('routeChangeStart', handleRouteChange)
    Router.events.on('routeChangeComplete', handleRouteComplete)
    Router.events.on('routeChangeError', handleRouteComplete)

    return () => {
      Router.events.off('routeChangeStart', handleRouteChange)
      Router.events.off('routeChangeComplete', handleRouteComplete)
      Router.events.off('routeChangeError', handleRouteComplete)
    }
  }, [])

  return (
    <>
      <Head />
      <Elements options={{ fonts }} stripe={stripePromise}>
        <ContextProvider>
          <Component {...pageProps} pageLoading={loading} />
        </ContextProvider>
      </Elements>
    </>
  )
}

MyApp.propTypes = {
  Component: func,
  pageProps: object
}

export default MyApp
