import React from 'react'
import { bool, node } from 'prop-types'

import styles from './VisuallyHidden.module.scss'

/**
 * Note: nothing is visible in the previews – the content is intentionally
 * visually hidden
 *
 * Hides content using CSS without using `display: none`.
 * This means screen-readers/bots can still access the content.
 *
 * This is useful for adding hidden labels/descriptions to icons or other
 * visual elements which might benefit screen-reader users.
 */

const VisuallyHidden = ({ children, visible }) => (
  <span className={!visible && styles.VisuallyHidden}>{children}</span>
)

VisuallyHidden.propTypes = {
  children: node.isRequired,
  visible: bool
}

export default VisuallyHidden
