import React, { createContext, useReducer } from 'react'
import { node } from 'prop-types'

import { dataReducer } from '../../../reducer/data-reducer'

const ValuationDataContext = createContext()

const ValuationDataProvider = ({ children }) => {
  const initialState = {
    questions: [],
    validation: {},
    vehicle: null,
    error: null
  }
  const [data, dispatch] = useReducer(dataReducer, initialState)
  return (
    <ValuationDataContext.Provider value={{ data, dispatch }}>
      {children}
    </ValuationDataContext.Provider>
  )
}

ValuationDataProvider.propTypes = {
  children: node.isRequired
}

export { ValuationDataContext, ValuationDataProvider }
